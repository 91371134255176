import { useState } from "react";
import { ChartFilterBlock, ChartFilterButton, ChartHeader, ChartSelected } from "./styled";
import ChartCandles from "./Candles";
import ChartLine from "./Line";
import SelectCommon from "../Select";

const ChartCommon = ({ height }: any) => {
  const [chartChoose, setChartChoose] = useState(2)
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('Hour');
  const [selectedAggregate, setSelectedAggregate] = useState<number>(1);
  const onChangeSelect = (e: any) => {
    setSelectedTimeframe(e)
    setSelectedAggregate(1)
  }
  return (
    <div>
      <ChartHeader>
        <ChartFilterBlock>
          <div>
            <SelectCommon defaultValue="Hour" data={[
              {
                title: "Minute",
                img: "",
              },
              {
                title: "Hour",
                img: "",
              },
              {
                title: "Day",
                img: "",
              },
            ]}
              onChange={onChangeSelect}
            />
          </div>
          <div>
            <div>
              {selectedTimeframe === 'Minute' && [1, 5, 15].map((agg) => (
                <ChartFilterButton>
                  <button className={agg === selectedAggregate ? "active" : ""} key={agg} onClick={() => setSelectedAggregate(agg)}>
                    {agg} minutes
                  </button>
                </ChartFilterButton>
              ))}
              {selectedTimeframe === 'Hour' && [1, 4, 12].map((agg) => (
                <ChartFilterButton>
                  <button className={agg === selectedAggregate ? "active" : ""} key={agg} onClick={() => setSelectedAggregate(agg)}>
                    {agg} hours
                  </button>
                </ChartFilterButton>
              ))}
              {selectedTimeframe === 'Day' && (
                <ChartFilterButton>
                  <button onClick={() => setSelectedAggregate(1)}>
                    1 day
                  </button>
                </ChartFilterButton>
              )}
            </div>
          </div>
        </ChartFilterBlock>
        <ChartSelected>
          {selectedChart.map((item, index) => {
            return (
              <li className={chartChoose === item.id ? "active" : ""} onClick={() => {
                setChartChoose(item.id)
              }} key={index}>
                <figure>
                  <img width={15} height={15} src={item.icon} alt="icon" loading="lazy" />
                </figure>
                <p>{item.text}</p>
              </li>
            )
          })}
        </ChartSelected>
      </ChartHeader>
      {chartChoose === 2 ? <ChartLine height={height} selectedTimeframe={selectedTimeframe} selectedAggregate={selectedAggregate} /> : <ChartCandles height={height} selectedTimeframe={selectedTimeframe} selectedAggregate={selectedAggregate} />}
    </div>
  )
}

const selectedChart = [
  {
    id: 1,
    text: 'Candles',
    icon: "./img/Common/line_chart.png"
  },
  {
    id: 2,
    text: 'Lines',
    icon: "./img/Common/candles_chart.png"
  },
]

export default ChartCommon