import {
  LiqFrame,
  LiqFrameHeader,
  LiqPerList,
  LiquidityActions,
  LiquidityAddWrapper,
  LiquidityFrame,
  LiquidityInfo,
  LiquidityQuote,
} from "./styled";
import ProfileCommon from "../../../components/Common/Profile";
import InputCommon from "../../../components/Common/Input";
import { ButtonCommon } from "../../../Layout/styled";
import HintCommon from "../../../components/Common/Hint";
import { ContextProviderWrapper } from "../../../components/Context";
import { useContext } from "react";
import { HintHeader } from "../../../components/Common/Hint/styled";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { convertFixed } from "../../../utils/convertNumber";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const LiquidityAdd = ({
  valueA,
  valueB,
  handleValueChange,
  addLiquidity,
  balanceA,
  balanceB,
  allowance,
  approveAddLiquidity,
  pendingApprove,
  loadingAddLiquidity
}: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const { address } = useAccount()
  const { open } = useWeb3Modal()
  const poolInfoData = useSelector((state: any) => state.user.poolInfo);
  const usdtPrice = useSelector((state: any) => state.user.usdtPrice);

  return (
    <LiquidityAddWrapper>
      <LiquidityFrame>
        <LiqFrame>
          <LiqFrameHeader>
            <ProfileCommon
              avatar="./img/Common/oni_icon.png"
              name="ONI"
              subName="BNB Smart Chain"
            />
            <div>
              <p>Your Balance</p>
              <span>{address && balanceA ? convertFixed(Number(balanceA)) : 0}</span>
            </div>
          </LiqFrameHeader>
          <InputCommon
            type="number"
            placeHolder="0.00"
            onChange={(e: any) => handleValueChange("A", e.target.value)}
            value={valueA}
          />
          <p style={{
            color: "#9f9f9f"
          }}>${convertFixed(Number(valueA) * Number(usdtPrice))}</p>
          <LiqPerList>
            {perData.map((item) => {
              return (
                <li key={item.id}
                  onClick={() => {
                    const convertNumber = (Number(balanceA) * item.value).toString()
                    handleValueChange("A", convertNumber.toString())
                  }}
                >
                  <p>{item.text}</p>
                </li>
              );
            })}
          </LiqPerList>
        </LiqFrame>
        <LiqFrame>
          <LiqFrameHeader>
            <ProfileCommon
              avatar="./img/Common/bnb_icon.png"
              name="BNB"
              subName="BNB Smart Chain"
            />
            <div>
              <p>Your Balance</p>
              <span>{address && balanceB ? convertFixed(Number(balanceB)) : 0}</span>
            </div>
          </LiqFrameHeader>
          <InputCommon
            type="number"
            placeHolder="0.00"
            onChange={(e: any) => handleValueChange("B", e.target.value)}
            value={valueB}
          />
          <p style={{
            color: "#9f9f9f"
          }}>${convertFixed(Number(valueA) * Number(usdtPrice))}</p>
          <LiqPerList>
            {perData.map((item) => {
              return (
                <li key={item.id}
                  onClick={() => {
                    const convertNumber = Number(balanceB) * item.value
                    handleValueChange("B", convertNumber.toString())
                  }}
                >
                  <p>{item.text}</p>
                </li>
              );
            })}
          </LiqPerList>
        </LiqFrame>
      </LiquidityFrame>
      <LiquidityInfo>
        <div style={{ justifyContent: isDesktop ? "center" : "" }}>
          <p>
            1 ONI = {parseFloat(poolInfoData?.base_token_price_quote_token) || "--"} BNB
            {/* <img
              width={18}
              height={18}
              src="./img/Common/exchange_icon.png"
              alt="icon"
              loading="lazy"
            /> */}
          </p>
          {!isDesktop && (
            <HintHeader>
              <div>
                <figure>
                  <img
                    width={14}
                    height={14}
                    src="./img/Common/hint_icon.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </figure>
                <p>How it works</p>
              </div>
            </HintHeader>
          )}
        </div>
      </LiquidityInfo>
      <LiquidityActions>
        {address ? <>
          {Number(valueA) >= Number(allowance) ? <ButtonCommon
            style={{
              width: "100%"
            }}
            disabled={pendingApprove}
            className="gray" onClick={() => {
              approveAddLiquidity()
            }}>
            {pendingApprove ? <Spin /> : <>Approve ONI</>}
          </ButtonCommon> :
            <ButtonCommon
              style={{
                width: "100%"
              }}
              disabled={loadingAddLiquidity}
              className="green" onClick={() => {
                if ((Number(!valueA) || Number(!valueB) || Number(valueA) === 0 || Number(valueB) === 0) || loadingAddLiquidity ||
                  ((Number(balanceA) < Number(valueA) || Number(balanceA) < Number(valueB)) && (Number(valueA) > 0 || Number(valueB) > 0))) {
                  <></>
                } else {
                  addLiquidity()
                }
              }}>
              {loadingAddLiquidity ? <Spin /> : <>
                {(Number(balanceA) < Number(valueA) || Number(balanceB) < Number(valueB)) && (Number(valueA) > 0 || Number(valueB) > 0) ?
                  "Not enough balance" : Number(!valueA) || Number(!valueB) || Number(valueA) === 0 || Number(valueB) === 0
                    ? "Please enter value" :
                    "Add Liquidity"}
              </>}
            </ButtonCommon>}
        </> : <ButtonCommon
          style={{
            width: "100%"
          }}
          className="green" onClick={() => { open() }}>
          Please Connect Wallet
        </ButtonCommon>}
      </LiquidityActions>
      {isDesktop && (
        <>
          <LiquidityQuote>
            <div>
              <p>Slippage</p>
              <span>Auto (2%)</span>
            </div>
          </LiquidityQuote>
          <HintCommon />
        </>
      )}
    </LiquidityAddWrapper>
  );
};

const perData = [
  {
    id: 1,
    value: .25,
    text: "25%"
  },
  {
    id: 2,
    value: .50,
    text: "50%"
  },
  {
    id: 3,
    value: .75,
    text: "75%"
  },
  {
    id: 4,
    value: 1,
    text: "100%"
  },
];

export default LiquidityAdd;
