import React, { useContext, useEffect, useState } from "react";
import { BlockTable } from "../styled";
import TableCommon from "../../../components/Common/Table";
import { shortenAddress } from "../../../utils/addressUser";
import { ContextProviderWrapper } from "../../../components/Context";
import { TablePairName } from "../../Liquidity/styled";
import { formatNuberMega } from "../../../utils/formatNumber";
import moment from "moment";
import { useSelector } from "react-redux";

const HistoryWithdraw = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const { userProfile }: any = useSelector((state: any) => state.user);
  const [dataHistory, setDataHistory] = useState<any>()
  console.log('dataHistory',dataHistory);

  useEffect(()=>{
    if(userProfile?.wallet) {
      const dataHistory = userProfile?.wallet?.transactions?.filter((item:any)=>item?.transactionType === 'withdraw');
      const reverDataHistory = [...dataHistory]?.reverse();
      setDataHistory(reverDataHistory)
    }
  },[userProfile])

  const headingData = [
    {
      name: "Id",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p>
            {!isDesktop && <span className="text-heading-mobile">Id: </span>}
            {shortenAddress(data.id)}
          </p>
        );
      },
    },
    {
      name: `Type`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const typeMapping: { [key: string]: string } = {
          joinPoolShare: "Participate Pool Share",
          bonusDepositONI: "Bonus Buy",
          withdraw: "Withdraw",
          staking: "Profit Daily",
          rewardOnReward: "Earn Profit",
          directCommission: "Reward",
        };
        return (
          <>
            <p>
              {!isDesktop && (
                <span className="text-heading-mobile">Type : </span>
              )}
              <span className="color-grey">{data.__typename}</span>
            </p>
          </>
        );
      },
    },
    {
      name: `Payment Type`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <>
            <p>
              {!isDesktop && (
                <span className="text-heading-mobile">Payment Type : </span>
              )}
              <span className="color-grey is-uppercase">ONI LPs</span>
            </p>
          </>
        );
      },
    },
    {
      name: `Amount`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <>
            <p
              style={{
                fontSize: !isDesktop ? "15px" : "",
                fontWeight: !isDesktop ? "400" : "",
              }}
            >
              {!isDesktop && (
                <span className="text-heading-mobile">Amount: </span>
              )}
              {formatNuberMega(data?.amount)}
            </p>
          </>
        );
      },
    },
    {
      name: `Description`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const note = data?.note || "";
        const regex = /(0x[a-fA-F0-9]{40})/;
        const parts = note.split(regex);
        return (
          <p>
            {!isDesktop && (
              <span className="text-heading-mobile">Description: </span>
            )}
            <span className="text-des" style={{ textAlign: "center" }}>
              {data.note ? data.note : "--"}
            </span>
          </p>
        );
      },
    },
    {
      name: `Created`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const createdAt = data?.createdAt;
        if (createdAt) {
          const timestamp = Number(createdAt);
          const time = moment(timestamp).format("HH:mm");
          const date = moment(timestamp).format("DD/MM/YYYY");
          const localDate = (
            <>
              <span>{date} - </span>
              <span className="time-highlight">{time}</span>
            </>
          );
          return (
            <p>
              {!isDesktop ? (
                <>
                  <span className="text-heading-mobile">Created: </span>
                  {localDate}
                </>
              ) : (
                <p className="time-transaction">{localDate}</p>
              )}
            </p>
          );
        }
      },
    },
  ];
  return (
    <BlockTable
      id="table-transaction"
    >
      <TableCommon
        data={dataHistory}
        heading={headingData}
      />
    </BlockTable>
  );
};

export default HistoryWithdraw;
