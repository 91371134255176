import React, { useContext } from 'react'
import { BlockTable } from '../styled'
import TableCommon from '../../../components/Common/Table'
import { shortenAddress } from '../../../utils/addressUser'
import { ContextProviderWrapper } from '../../../components/Context'
import { TablePairName } from '../../Liquidity/styled'
import { formatNuberMega } from '../../../utils/formatNumber'
import moment from "moment";

const TableTransaction = ({newTransactions}:any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const headingData = [
    {
      name: "Id",
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <p
            style={{
              textAlign: "center",
            }}
          >
            {!isDesktop && <span className="text-heading-mobile">Id: </span>}
            {shortenAddress(data.id)}
          </p>
        );
      },
    },
    {
      name: `Type`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const typeMapping: { [key: string]: string } = {
          joinPoolShare: "Participate Pool Share",
          bonusDepositONI: "Bonus Buy",
          withdraw: "Withdraw",
          staking: "Profit Daily",
          rewardOnReward: "Earn Profit",
          directCommission: "Reward",
        };
        return (
          <>
            <TablePairName style={{justifyContent: 'center'}}>
              {!isDesktop && (
                <span className="text-heading-mobile">Type : </span>
              )}
              <span className='color-grey'>{typeMapping[data?.transactionType] || "Reward"}</span>
            </TablePairName>
          </>
        );
      },
    },
    {
      name: `Payment Type`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <>
            <TablePairName>
              {!isDesktop && (
                <span className="text-heading-mobile">Payment Type : </span>
              )}
              <span className="color-grey is-uppercase">
                {data?.walletType}
              </span>
            </TablePairName>
          </>
        );
      },
    },
    {
      name: `Amount`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        return (
          <>
            <p
              style={{
                fontSize: !isDesktop ? "15px" : "",
                fontWeight: !isDesktop ? "400" : "",
              }}
            >
              {!isDesktop && (
                <span className="text-heading-mobile">Amount: </span>
              )}
              {formatNuberMega(data?.amount)}
            </p>
          </>
        );
      },
    },

    {
      name: `Description`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const note = data?.note || "";
        const regex = /(0x[a-fA-F0-9]{40})/;
        const parts = note.split(regex);
        return (
          <>
            {!isDesktop && (
              <span className="text-heading-mobile">Description: </span>
            )}
            <span className="text-des">
              {parts.map((part: any, index: any) =>
                regex.test(part) ? (
                  <span key={index} className="address-highlight">
                    {shortenAddress(part)}
                  </span>
                ) : (
                  <span key={index}>{part}</span>
                )
              )}
            </span>
          </>
        );
      },
    },
    {
      name: `Created`,
      dataIndex: "",
      key: "",
      render: (data: any) => {
        const createdAt = data?.createdAt;
        if (createdAt) {
          const timestamp = Number(createdAt);
          const time = moment(timestamp).format("HH:mm");
          const date = moment(timestamp).format("DD/MM/YYYY");
          const localDate = (
            <>
              <span>{date}</span>
              <span className="time-highlight">{time}</span>
            </>
          );
          return (
            <>
              {!isDesktop ? (
                <>
                  <span className="text-heading-mobile">Created: </span>
                  {localDate}
                </>
              ) : (
                <p className="time-transaction">{localDate}</p>
              )}
            </>
          );
        }
      },
    },
  ];
  return (
      <BlockTable id='table-transaction'>
        <TableCommon
          data={newTransactions && newTransactions}
          heading={headingData}
        />
      </BlockTable>
  )
}

export default TableTransaction