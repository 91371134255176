import styled from "styled-components";

export const AppContainer = styled.div`
  min-height: 100vh;
  color: #fff;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  --angle: 0deg;
  --start-angle: 0deg;
  @property --angle {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
  }
`;
