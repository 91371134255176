import React, { useContext, useEffect, useState } from 'react'
import { PoolModalButton, PoolModalContainer, PoolModalShare, PoolModalStake, PoolModalWrapper } from './styled'
import { Modal, Spin } from 'antd'
import './modal.css'
import InputCommon from '../../../components/Common/Input'
import { ContextProviderWrapper } from '../../../components/Context'
import { useAccount, useSignMessage } from "wagmi";
import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { formatNuberMega } from '../../../utils/formatNumber'
import moment from "moment";

const LEAVEPOOL = gql`
  mutation leavePoolShare(
    $id: String!
    $address: String!
    $amount: Float!
    $signature: String!
    $message: String!
  ) {
    leavePoolShare(
      id: $id
      address: $address
      amount: $amount
      signature: $signature
      message: $message
    )
  }
`;

const PoolModal = ({ isModalOpen, handleCancel,itemDataHistory, getWallet,setLoadingData }: any) => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const { data: signature, signMessage }: any = useSignMessage();
  const { address } = useAccount();
  const verifyMessage = "Verify";
  const [leavepool] = useMutation(LEAVEPOOL);
  const [dataValue, setDataValue] = useState()
  const [loadingLeave, setLoadingLeave] = useState(false)
  const [remainingTime, setRemainingTime] = useState<number>(0);

  const onChangeInput = (e:any) => {
    setDataValue(e.target.value)
  }

  //withdraw
  const handleVerifyWallet = async () => {
    await signMessage({ message: verifyMessage });
  };

  const handleGetSignature = async () => {
    setLoadingLeave(true)
    handleVerifyWallet();
  };

  const handleWithdraw = async () => {
    try {
      const res = await leavepool({
        variables: {
          id: itemDataHistory?.id,
          address,
          signature,
          message: "Verify",
          amount: Number(dataValue)
        },
      });
      if (res?.data?.leavePoolShare) {
        toast.success("Success");
        handleCancel()
        await getWallet({ variables: { address } });
        setLoadingData(true)
        setLoadingLeave(false)
        setTimeout(()=>{
          setLoadingData(false)
        },3000)
      }
    } catch (error:any) {
      setLoadingLeave(false)
      console.log(error?.message);
      toast.error("Fail");
    }
  };

  useEffect(() => {
    if (signature) {
      handleWithdraw();
    }
  }, [signature]);

  // Calculate initial remaining time when modal opens
  useEffect(() => {
    if (itemDataHistory?.expiredAt) {
      const expiredAt = moment(Number(itemDataHistory.expiredAt));
      const now = moment();
      const duration = expiredAt.diff(now); // Get the difference in milliseconds
      setRemainingTime(duration);
    }
  }, [itemDataHistory]);

  // Countdown logic
  useEffect(() => {
    if (remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000); // Decrease by 1 second (1000 ms)
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [remainingTime]);

  // Calculate and format the remaining time (D:H:M:S)
  const formatRemainingTime = () => {
    if (remainingTime <= 0) {
      return '00D:00H:00M:00S';
    }

    const duration = moment.duration(remainingTime);

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days}D:${hours}H:${minutes}M:${seconds}S`;
  };

  return (
    <Modal open={isModalOpen} onCancel={handleCancel} footer={false} className='pool-modal'>
      <PoolModalContainer>
        <PoolModalWrapper>
          <div className="content-heading">
            <div className="content-left">
              <h2 className="title-content">Stake Unlocking</h2>
              <p className="text-content">You can unlock in: <span>{formatRemainingTime()}</span></p>
            </div>
            {isDesktop && <div className="content-right">
              <img src="/img/Dashboard/Pool/img_modal_01.png" width={68} loading='eager' alt="Stake Unlocking" />
            </div>}

          </div>
          <PoolModalShare>
            <h3 className="title-share">Your Share Pool</h3>
            <p className="number-share">{itemDataHistory?.amountAvlbLeave > 0 ? <>{formatNuberMega(itemDataHistory?.amountAvlbLeave - itemDataHistory?.amountLeave)}<span>/{formatNuberMega(itemDataHistory?.amount)}<img src="/img/Dashboard/Pool/icn_token_small.png" width={32} height={32} loading='eager' alt="Token" /></span></> : itemDataHistory?.amount}</p>
            <p className="text-share"><span>Unlock time depends on stake start time and Stake Period.</span></p>
          </PoolModalShare>
          <PoolModalStake>
            <h3 className="title-stake">Stake Amount</h3>
            <InputCommon
              placeHolder="Enter amount"
              onChange={onChangeInput}
            />
          </PoolModalStake>
          <PoolModalButton disabled={remainingTime > 0 ? true : false} onClick={handleGetSignature}>{loadingLeave ? <Spin /> : 'Confirm & Unlock'}</PoolModalButton>
        </PoolModalWrapper>
      </PoolModalContainer>
    </Modal>
  )
}

export default PoolModal