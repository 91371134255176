import {
  HeaderActions,
  HeaderBody,
  HeaderLogo,
  HeaderNavigation,
  HeaderWrapper,
} from "./styled";
import { Link } from "react-router-dom";
import { ButtonCommon } from "../../Layout/styled";
import { ContextProviderWrapper } from "../Context";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { shortenAddress } from "../../utils/addressUser";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount} from "wagmi";

const Header = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { isDesktop } = useContext(ContextProviderWrapper)!;

  const [showMore, setShowMore] = useState(false);

  const linkData = [
    {
      title: "Dashboard",
      href: "/",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_01.png',
      animationDuration: '0.1s',
    },
    {
      title: "Liquidity",
      href: "/liquidity",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_02.png',
      animationDuration: '0.2s',
    },
    {
      title: "Mining",
      href: "/mining",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_03.png',
      animationDuration: '0.3s',
    },
    {
      title: "Swap",
      href: "/swap",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_04.png',
      animationDuration: '0.4s',
    },
    {
      title: "Network",
      href: "/network",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_06.png',
      animationDuration: '0.5s',
    },
    {
      title: "Buy ONI",
      href: "/buy",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_09.png',
      animationDuration: '0.6s',
    },
    // {
    //   title: "Launchpad",
    //   href: "/launchpad",
    //   scrollTo: false,
    //   externalLink: false,
    //   icon: '/img/Common/sp/icn_navigation_07.png',
    //   animationDuration: '0.3s',
    // },
    // {
    //   title: isDesktop ? "ONI Bot" : "Bot",
    //   href: "/bot",
    //   scrollTo: false,
    //   externalLink: false,
    //   icon: '/img/Common/sp/icn_navigation_08.png',
    //   animationDuration: '0.45s',
    // },
    // {
    //   title: isDesktop ? "ONI Overview" : "Overview",
    //   href: "/overview",
    //   scrollTo: false,
    //   externalLink: false,
    //   icon: '/img/Common/sp/icn_navigation_05.png',
    //   animationDuration: '0.7s',
    // },
    {
      title: 'Withdraw',
      href: "/withdraw",
      scrollTo: false,
      externalLink: false,
      icon: '/img/Common/sp/icn_navigation_10.png',
      animationDuration: '0.8s',
    },
    // {
    //   title: 'Pool',
    //   href: "/pool",
    //   scrollTo: false,
    //   externalLink: false,
    //   icon: '/img/Common/sp/icn_navigation_11.png',
    //   animationDuration: '0.9s',
    // },
  ];

  return (
    <>
      {isDesktop ? (
        <HeaderBody>
          <HeaderWrapper>
            <HeaderLogo>
              <Link to="/">
                <img
                  width={176}
                  height={37}
                  src="./img/Common/logo.png"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            </HeaderLogo>
            <HeaderNavigation>
              {linkData.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink to={item.href}>{item.title}</NavLink>
                  </li>
                );
              })}
            </HeaderNavigation>
            <HeaderActions>
              <ButtonCommon className="yellow">
                <p>
                  <img
                    width={24}
                    height={24}
                    src="./img/Common/bnb_logo.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </p>
                BNB Chain
              </ButtonCommon>
              <ButtonCommon className="green" onClick={() => {
                open()
              }}>
                <p>{address?.slice(0, 2) || "ONI"}</p>
                {address ? shortenAddress(address).slice(2, 100) : "Connect Wallet"}
              </ButtonCommon>
              {/* <ButtonCommon className="shadow">
                <img src="./img/Common/setting.svg" alt="icon" loading="lazy" />
              </ButtonCommon> */}
            </HeaderActions>
          </HeaderWrapper>
        </HeaderBody>
      ) : (
        <HeaderBody>
          <HeaderWrapper>
            <HeaderLogo>
              <Link to="/">
                <img
                  width={46}
                  height={53}
                  src="./img/Common/sp/logo.png"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            </HeaderLogo>
            <HeaderActions>
              <ButtonCommon className="yellow">
                <p>
                  <img
                    width={24}
                    height={24}
                    src="./img/Common/bnb_logo.svg"
                    alt="icon"
                    loading="lazy"
                  />
                </p>
              </ButtonCommon>
              <ButtonCommon className="green" onClick={() => {
                open()
              }}>
                <p>{address?.slice(0, 2) || "ONI"}</p>
                {address ? shortenAddress(address).slice(2, 100) : "Connect Wallet"}
              </ButtonCommon>
              {/* <ButtonCommon className="shadow">
                <img src="./img/Common/setting.svg" alt="icon" loading="lazy" />
              </ButtonCommon> */}
            </HeaderActions>
          </HeaderWrapper>
          <HeaderNavigation>
            {isDesktop ? <>
              {linkData.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink to={item.href}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </> : <>
              {linkData.slice(showMore ? 4 : 0, showMore ? 8 : 4).map((item, index) => {
                return (
                  <li key={index} >
                    <NavLink to={item.href} style={{
                      animationDuration: `${item.animationDuration}`,
                    }} className={showMore ? "animation-more" : "animation-less"}>
                      <img width={29} height={29} src={item.icon} alt="icon" loading="lazy" />
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </>}
            {!isDesktop && <li onClick={() => {
              setShowMore(!showMore)
            }}>
              <NavLink to="#">
                <img src='./img/Common/sp/icn_navigation_05.png' alt="icon" loading="lazy" />
                {showMore ? "Back" : "More"}
              </NavLink>
            </li>}
          </HeaderNavigation>
        </HeaderBody>
      )}
    </>
  );
};

export default Header;
