import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

interface NetworkItemProps {
  team: number;
  totalMember: string;
  totalStaking: string;
  handleClickTeam: any;
  setIsModalOpen: any;
  record: any
}

const NetworkItem: React.FC<NetworkItemProps> = ({
  team,
  totalMember,
  totalStaking,
  handleClickTeam,
  setIsModalOpen,
  record
}) => {
  const [showStaking, setShowStaking] = useState(true);
  const { isDesktop, netWorkMember } = useContext(ContextProviderWrapper)!;
  const totalInnerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0)

  const handleShowStaking = () => {
    setShowStaking(!showStaking);
  };

  const handleShowMember = () => {
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (showStaking && totalInnerRef.current) {
      const height = totalInnerRef.current.getBoundingClientRect().height;
      setHeight(height)
    }
  }, [showStaking]);

  return (
    <>
      <div onClick={() => {
        handleClickTeam(team)
      }} className={`block-row ${showStaking ? "is-active" : ""}`}>
        <div className="block-row-heading">
          <p className="name">Team {team < 10 ? "0" + team : team}</p>
          <p className="more" onClick={handleShowStaking}>
            {!showStaking ? 'Show less' : 'See more'}
            <img src="/img/Common/play_icon.png" alt="more" />
          </p>
        </div>
        <div className="total-staking" style={{ height: showStaking ? height : '0' }}>
          {showStaking ? (
            <div className="block-content" ref={totalInnerRef}>
              <div className="total-inner">
                <p className="text-total">Total Member</p>
                <span className="number-staking">{totalMember || 0}
                  <img
                    style={
                      {
                        marginLeft: '5px'
                      }
                    }
                    width={25}
                    height={24}
                    src="./img/Common/ppl_icon.png"
                    alt="icon"
                    loading="lazy"
                  />
                </span>
                <span className={`show-detail ${netWorkMember && 'is-active'}`} onClick={handleShowMember}>
                  <img src="/img/Common/arrow_right.png" alt="" />
                </span>
              </div>
              <div className="total-inner">
                <p className="text-total">Total Staking</p>
                <span className="number-staking">{totalStaking}</span>
              </div>
            </div>
          ) : (
            <div className="total-inner">
              <p className="text-total">Total Staking</p>
              <span className="number-staking">{totalStaking}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NetworkItem;
