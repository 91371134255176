import styled from "styled-components";
import { WithdrawProject, WithdrawText, WithdrawTitle } from "../Withdraw/styled";

export const PoolWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  &:after {
    position: absolute;
    content: '';
    right: 80px;
    top: 0;
    background: url('/img/Dashboard/Pool/pool_bg.png') center / cover no-repeat;
    width: 1034px;
    height: 537px;
    pointer-events: none;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    &:after {
      content: none;
    }
    ${WithdrawProject} {
      margin-bottom: 10px;
    }
    ${WithdrawTitle} {
      font-size: 36px;
    }
    ${WithdrawText} {
      font-size: 15px;
    }
  }
`
export const PoolLeft = styled.div`
  width: 70%;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: -370px;
    top: -300px;
    width: 921px;
    height: 921px;
    background: radial-gradient(38.23% 38.23% at 50% 50%, rgba(113, 255, 137, 0.18) 0%, rgba(113, 255, 137, 0.00) 100%);
    pointer-events: none;
    filter: blur(100px);
    border-radius: 50%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    &:before {
      content: none;
    }
  }
`
export const PoolImage = styled.div`
  text-align: right;
  margin-bottom: 15px;
  padding-right: 30px;
  @media screen and (max-width: 1024px) {
    padding-right: 0;
    margin-right: -10px;
  }
`
export const PoolList = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  .content-left {
    display: flex;
    gap: 10px;
    max-width: 63.4%;
    width: 100%;
  }
  .item-list {
    width: 100%;
    position: relative;
    min-height: 168px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    overflow: hidden;
    &:before {
      position: absolute;
      content: '';
      width: 695px;
      height: 201px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: url('/img/Dashboard/Pool/pool_bg_02.png') center / cover no-repeat;
      pointer-events: none;
    }
    &:nth-child(1) {
      border-radius: 8px;
      border: 1px solid var(--Number, #CEFFD1);
      background: linear-gradient(180deg, rgba(66, 168, 147, 0.31) 0%, rgba(61, 160, 138, 0.50) 100%);
    }
    &:nth-child(2) {
      border-radius: 8px;
      border: 1px solid var(--Number, #CEFFD1);
      background: var(--Green-Stroke, linear-gradient(180deg, #6EA380 0%, #1F1F1F 100%));
    }
  }
  .image-oni {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: .2;
  }
  .item-title {
    color: #FFF;
    font-size: 15px;
    margin-bottom: 3px;
  }
  .item-number {
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 5px;
    span {
      color: #5FB6AA;
      font-size: 24px;
      font-weight: 500;
    }
  }
  .button-contract {
    background: unset;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #C7C7C7;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.40));
    background: rgba(92, 92, 92, 0.70);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    padding: 6px 8px;
    width: 126px;
  }
  .text-reward {
    color: #FFF;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px;
  }
  .text-profit {
    color: #BFBFBF;
    font-size: 13px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    .content-left {
      max-width: unset;
    }
  }
`
export const PoolDetail = styled.div`
  border-radius: 8px;
  background: #222125;
  padding: 15px;
  width: calc(100% - 63.4%);
  .title-detail {
    color: var(--Mint-Green-300, #81EE97);
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: solid 1px #323232;
  }
  .list-pool-detail {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .text-left {
      color: var(--Gray-gray-200, #C8C8C8);
      font-size: 14px;
    }
    .text-right {
      color: var(--Gray-gray-100, #DADADA);
      font-size: 14px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1024px) {
    width: calc(100% - 30px);
  }
`
export const PoolReward = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Gray-Stroke, linear-gradient(180deg, #3D3D3D 0%, #2C2C2C 100%));
  padding: 12px 8px;
  .title-reward {
    color: var(--Neutral-White, #FFF);
    font-size: 12px;
    font-weight: 500;
  }
  .number-reward {
    color: var(--Neutral-White, #FFF);
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  .text-reward {
    color: #BFBFBF;
    font-size: 13px;
  }
`
export const PoolRight = styled.div`
  width: 30%;
  min-width: 400px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
export const PoolRightStake = styled.div`
  border-radius: 6px;
  border: 1px solid #323232;
  background: #191919;
  padding: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  .title-stake {
    color: var(--Neutral-White, #FFF);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .text-stake {
    color: var(--Sub-light-text, #9F9F9F);
    font-size: 13px;
    position: relative;
    padding-left: 18px;
    margin-bottom: 16px;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      margin-top: -7px;
      left: 0;
      width: 14px;
      height: 14px;
      background: url('/img/Dashboard/mining/icn_warning.png') center / cover no-repeat;
    }
  }
  .block-stake {
    border-radius: 6px;
    background: #222125;
    padding: 20px 16px;
    margin-bottom: 10px;
  }
  .number-stake {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: var(--Neutral-White, #FFF);
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .list-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      outline: none;
      border: none;
      background: none;
      color: var(--Neutral-White, #FFF);
      font-size: 16px;
      font-weight: 500;
      padding: 10px 23px;
      &.button-plan {
        border-radius: 8px;
        border: 1px solid #BBF7C6;
        background: radial-gradient(105.75% 89.98% at 2.11% 14.58%, rgba(129, 238, 151, 0.00) 0%, rgba(129, 238, 151, 0.20) 100%), rgba(35, 35, 35, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(248, 248, 248, 0.60) inset, 0px 8px 24px -16px rgba(0, 0, 0, 0.40);
        backdrop-filter: blur(10px);
      }
      &.button-more {
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: var(--Surface-Background, rgba(40, 40, 40, 0.70));
        background-blend-mode: luminosity;
        box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
        backdrop-filter: blur(50px);
      }
    }
  }
  .stake-reward {
    border-radius: 8px;
    background: var(--Green-Stroke, linear-gradient(180deg, #6EA380 0%, #1F1F1F 100%));
    padding: 29px 12px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 450px;
      height: 150px;
      background: url('/img/Dashboard/Pool/pool_bg_03.png') center / cover no-repeat;
      pointer-events: none;
    }
    .title-reward {
      color: var(--Neutral-White, #FFF);
      text-align: center;
      font-size: 15px;
      line-height: 1;
      margin-bottom: 0px;
    }
    .number-reward {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      color: var(--Neutral-White, #FFF);
      font-size: 24px;
      font-weight: 500;
    }
    .button-reward {
      outline: none;
      background: none;
      border: none;
      color: var(--Neutral-White, #FFF);
      font-size: 16px;
      font-weight: 500;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.40);
      background: var(--Surface-Background, rgba(40, 40, 40, 0.70));
      background-blend-mode: luminosity;
      box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
      backdrop-filter: blur(50px);
      width: 106px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      transition: opacity .25s ease;
      &:disabled {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .stake-reward {
      display: block;
      &:before {
        width: 100%;
        height: 100%;
      }
    }
  }
`

export const BlockHistory = styled.div`
  border-radius: 6px;
  border: 1px solid #323232;
  background: #191919;
  padding: 20px;
  .title-history {
    color: var(--Neutral-White, #FFF);
    font-family: Rubik;
    font-size: 18px;
    font-weight: 500;
  }
  .text-history {
    color: var(--Sub-light-text, #9F9F9F);
    font-size: 13px;
    margin-bottom: 10px;
  }
  //table
  .ant-table-wrapper table {
    min-width: unset;
  }
  .amout {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .ant-table-content {
    .ant-table-cell {
      padding: 13px 11px;
    }
    .ant-table-thead {
      background: #1D1D1D;
      .ant-table-cell {
        color: var(--Neutral-White, #FFF);
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.3px;
      }
    }
    .ant-table-tbody {
      .index {
        color: var(--Sub-light-text, #9F9F9F);
        font-size: 16px;
      }
      .table-text {
        color: var(--Neutral-White, #FFF);
        font-size: 16px;
        letter-spacing: -0.32px;
      }
      .amount {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .ant-table-content {
      .ant-table-row {
        display: table-row;
      }
      .ant-table-thead {
        display: table-header-group;
        border-radius: 10px;
      }
      .ant-table-row {
        flex-direction: unset;
        align-items: center;
      }
    }
  }
`
export const TitleMobile = styled.h2`
  color: var(--Neutral-White, #FFF);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 24px 0 12px;
`

// detail page
export const PoolContainer = styled.div`
  &.detail-page {
    ${PoolRight} {
      min-width: unset;
    }
  @media screen and (max-width: 1024px) {
      ${PoolWrapper} {
        flex-direction: column-reverse;
      }
      ${PoolReward},
      ${PoolList},
      ${TitleMobile},
      ${BlockHistory} {
        display: none;
      }
      
      //table
      .ant-table-row {
        flex-direction: unset;
      }
    }
  }
`