import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import Header from "./components/Header";
import { AppContainer } from "./styled";
import "./App.css";
import { ApolloProvider } from "@apollo/client";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { bsc } from "viem/chains";
import { useEffect } from "react";
import Dashboard from "./page/Dashboard";
import DashboardWrapper from "./Layout/Dashboard";
import Swap from "./page/Swap";
import Overview from "./page/Overview";
import Liquidity from "./page/Liquidity";
import Mining from "./page/Mining";
import Network from "./page/Network";
import { client } from "./services/graphApi";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Buy from "./page/Buy";
import Pool from "./page/Pool";
import Withdraw from "./page/Withdraw";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const queryClient = new QueryClient();
  const projectId = "c06e8851ab55aa5954efec46c47d233e";
  const metadata = {
    name: "Oni Protocol",
    description: "Oni Kit",
    url: "https://oniprotocol.io/",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  };

  const chains = [bsc] as const;
  const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
  });

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <ContextProvider>
              <Toaster position="top-right" reverseOrder={false} />
              <AppContainer>
                <Header />
                <Routes>
                  <Route element={<DashboardWrapper />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/swap" element={<Swap />} />
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/liquidity" element={<Liquidity />} />
                    <Route path="/mining" element={<Mining />} />
                    <Route path="/network" element={<Network />} />
                    <Route path="/buy" element={<Buy />} />
                    <Route path="/pool" element={<Pool />} />
                    <Route path="/withdraw" element={<Withdraw />} />
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </AppContainer>
            </ContextProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
