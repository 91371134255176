import { Tooltip } from "antd";
import ProfileCommon from "../../components/Common/Profile";
import TitleCommon from "../../components/Common/Title";
import { BlockFrame, ButtonCommon } from "../../Layout/styled";
import {
  BalanceBlock,
  BalanceBlockHeader,
  BalanceHeader,
  BalanceListToken,
  BalanceToken,
  DashboardBalance,
  DashboardBottom,
  DashboardContainer,
  DashboardContent,
  DashboardMaxOut,
  DashboardMaxOutMain,
  DashboardTop,
  DashboardTopHeading,
  DashboardTopInner,
  DashboardTopTitle,
  LevelBody,
  LevelCard,
  LevelContent,
  LevelHeader,
  LevelInfo,
  LevelUpBlock,
  LevelUpContent,
  LevelUpTop,
  LevelWrapper,
  MaxOutCircular,
  MaxOutInfo,
  MaxoutLeft,
  PotentialPoolList,
  PriceUsd,
} from "./styled";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { ContextProviderWrapper } from "../../components/Context";
import { useContext, useEffect, useState } from "react";
import DashboardNoti from "../../components/Common/DashboardNoti";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { miningDataDashboard } from "../../constant";
import { MiningReward } from "../Mining/styled";
import { formatNuberMega } from "../../utils/formatNumber";
import { convertFixed } from "../../utils/convertNumber";

const Dashboard = () => {
  const { isMobile, isDesktop } = useContext(ContextProviderWrapper)!;
  const poolInfoData = useSelector((state: any) => state.user.poolInfo);
  const { userProfile }: any = useSelector((state: any) => state.user);
  const { address } = useAccount();
  const [valueCircle, setValueCircle] = useState(false);
  const usdtPrice = useSelector((state: any) => state.user.usdtPrice);

  const potentialPool = [
    {
      id: 1,
      href: "#",
      button: "Your LP Added",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalRewardStaking)
        : "--",
      img: "/img/Dashboard/icon_symbol_01.png",
      name: "Profit Daily",
      subName: "",
      yourLP: "Daily Earn (ONI Token)",
      icon: "/img/Dashboard/icn_balance_02.png",
      priceUsd: userProfile?.wallet ? formatNuberMega(userProfile?.wallet?.totalRewardStaking * usdtPrice) : '--'
    },
    {
      id: 2,
      href: "#",
      button: "Add more LP",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalDirectCommission)
        : "--",
      img: "/img/Dashboard/icon_symbol_02.png",
      name: "Network Reward",
      subName: "",
      yourLP: "Reward Earned (USDT)",
      icon: "/img/Dashboard/icn_balance_01.png",
    },
    {
      id: 3,
      href: "#",
      button: "Add more LP",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalLevelCommission)
        : "--",
      img: "/img/Dashboard/icon_symbol_03.png",
      name: "Ranking Bonus",
      subName: "",
      yourLP: "Bonus Earned (USDT)",
      icon: "/img/Dashboard/icn_balance_01.png",
    },
  ];

  const potentialPoolSP1 = [
    {
      id: 1,
      href: "#",
      button: "Add more LP",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalRewardStaking)
        : "--",
      img: "/img/Dashboard/icon_symbol_03.png",
      name: "Profit Daily",
      subName: "Description",
      yourLP: "Daily Earn (ONI Token)",
      icon: "/img/Dashboard/icn_balance_02.png",
      priceUsd: userProfile?.wallet ? formatNuberMega(userProfile?.wallet?.totalRewardStaking * usdtPrice) : '--'
    },
  ];

  const potentialPoolSP2 = [
    {
      id: 2,
      href: "#",
      button: "Add more LP",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalDirectCommission)
        : "--",
      img: "/img/Dashboard/icon_symbol_02.png",
      name: "Network Reward",
      subName: "Description",
      yourLP: "Reward Earned (USDT)",
      icon: "/img/Dashboard/icn_balance_01.png",
    },
    {
      id: 3,
      href: "#",
      button: "Add more LP",
      value: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.totalLevelCommission)
        : "--",
      img: "/img/Dashboard/icon_symbol_03.png",
      name: "Ranking Bonus",
      subName: "Description",
      yourLP: "Bonus Earned (USDT)",
      icon: "/img/Dashboard/icn_balance_01.png",
    },
  ];

  const listBalance = [
    {
      text: "USDT Balance",
      number: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.usdt.balance)
        : "--",
      icon: "/img/Dashboard/icn_balance_01.png",
    },
    {
      text: "ONI Token",
      number: userProfile?.wallet
        ? formatNuberMega(userProfile?.wallet?.oni.balance)
        : "--",
      icon: "/img/Dashboard/icn_balance_02.png",
      lastText: "Compared to last 24h",
      percen: poolInfoData?.price_change_percentage?.h24,
      priceUsd: userProfile?.wallet ? formatNuberMega(userProfile?.wallet?.oni?.balance * usdtPrice) : '--'
    },
  ];

  const swiperData = [
    {
      id: 1,
      rank: "Your Ranked",
      description: "Join ONI protocol and develop Validator",
      value:
        userProfile?.wallet?.level === 0 && address
          ? "Member"
          : userProfile?.wallet?.level > 0
          ? `Validator ${userProfile?.wallet?.level}`
          : "Connect Wallet",
    },
    // {
    //   id: 2,
    //   rank: "Your Ranked",
    //   description: "Join ONI protocol and develop Validator",
    //   value: "Validator2",
    // },
    // {
    //   id: 3,
    //   rank: "Your Ranked",
    //   description: "Join ONI protocol and develop Validator",
    //   value: "Validator2",
    // },
    // {
    //   id: 4,
    //   rank: "Your Ranked",
    //   description: "Join ONI protocol and develop Validator",
    //   value: "Validator2",
    // },
  ];

  //animation circle
  const animationCircle = () => {
    setValueCircle(true);
    setTimeout(() => {
      setValueCircle(false);
    }, 2000);
  };
  useEffect(() => {
    animationCircle();
  }, []);

  // Function to check which mining data matches the totalStaking value
  const getMiningData = (totalStaking: number) => {
    return miningDataDashboard.find(
      (item) => totalStaking >= item.valueFrom && totalStaking <= item.valueTo
    );
  };

  const miningData = getMiningData(userProfile?.wallet?.totalStaking || 0);

  return (
    <DashboardContainer>
      {!isMobile ? (
        <>
          <DashboardContent>
            <DashboardTop>
              <BlockFrame>
                <DashboardTopHeading>
                  <TitleCommon
                    title="Dashboard"
                    description="My top pool of potential candidates for the position"
                  />
                  {/* <div className="content-pack">
                    <img
                      src="/img/Dashboard/icn_pack.png"
                      width={44}
                      height={44}
                      alt="Pack"
                    />
                    <p>Hawking DataProc-01</p>
                  </div> */}
                </DashboardTopHeading>
                <PotentialPoolList>
                  {potentialPool.map((item, index) => {
                    return (
                      <li key={index} className={`item-${item.id}`}>
                        <div>
                          <ProfileCommon
                            avatar={item.img}
                            name={item.name}
                            subName={item.subName}
                          />
                        </div>
                        <div>
                          <span>{item.yourLP}</span>
                          <p>
                            {item.value}{" "}
                            <img src={item.icon} width={32} alt="" />
                          </p>
                          {item?.priceUsd && <PriceUsd>${item.priceUsd}</PriceUsd>}
                        </div>
                      </li>
                    );
                  })}
                </PotentialPoolList>
              </BlockFrame>
              <BlockFrame
                style={{
                  background: `url("./img/Dashboard/bg_maxout.png") no-repeat center / 100% 100%`,
                }}
              >
                <DashboardMaxOut>
                  <MaxoutLeft>
                    <ProfileCommon
                      avatar="/img/Dashboard/icn_maxOut.png"
                      name="Max-Out"
                      subName="Dashboard"
                    />
                    <p>
                      Total Profit
                      <br />{" "}
                      <span>
                        ${address && userProfile?.wallet
                          ? formatNuberMega(userProfile?.wallet?.maxout)
                          : 0}
                        /
                        ${address && userProfile?.wallet
                          ? formatNuberMega(
                              userProfile?.wallet?.totalStaking * 3
                            )
                          : 0}
                      </span>
                    </p>
                  </MaxoutLeft>
                  <MaxOutCircular>
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: `#5EE978`,
                      })}
                      background={false}
                      value={
                        valueCircle
                          ? 100
                          : address && userProfile?.wallet?.maxout > 0
                          ? (userProfile?.wallet?.maxout /
                              (userProfile?.wallet?.totalStaking * 3)) *
                            100
                          : 0
                      }
                    />
                    <MaxOutInfo>
                      <div>
                        <p>
                          {(address && userProfile?.wallet?.maxout > 0)
                            ? Number(
                                (userProfile?.wallet?.maxout /
                                  (userProfile?.wallet?.totalStaking * 3)) *
                                100
                              )?.toFixed(2)
                            : '0'}
                          %
                        </p>
                        <p>Percentage</p>
                      </div>
                      {/* <span>Updated: 15/08/2024</span> */}
                    </MaxOutInfo>
                  </MaxOutCircular>
                </DashboardMaxOut>
              </BlockFrame>
            </DashboardTop>
            <DashboardBottom>
              <DashboardBalance>
                <BalanceHeader>
                  <TitleCommon
                    title="Total Balance"
                    description="The sum of all amount on my wallet"
                  />
                </BalanceHeader>
                <BlockFrame>
                  <BalanceBlock>
                    <BalanceBlockHeader>
                      <h2>
                        $
                        <span>
                          {userProfile?.wallet
                            ? formatNuberMega((
                              userProfile?.wallet?.usdt.balance +
                              userProfile?.wallet?.oni?.balance * poolInfoData?.base_token_price_usd
                            ))
                            : "0"}
                        </span>
                      </h2>
                    </BalanceBlockHeader>
                    <p>Total USDT & ONI Balance</p>
                    <BalanceToken>
                      <BalanceListToken>
                        {listBalance.map((item: any, index: number) => (
                          <li key={index}>
                            <p className="text-balance">{item.text}</p>
                            <div className="content-number">
                              <span className="text-number">{item.number}</span>
                              <img src={item.icon} alt={item.text} />
                            </div>
                            {item.priceUsd && <PriceUsd>${item.priceUsd}</PriceUsd>}
                            {item.lastText && (
                              <>
                                <p className="last-text">
                                  {item.lastText} <span className={poolInfoData && poolInfoData?.price_change_percentage?.h24 > 0 ? 'increase' : 'decreasing'}>{item.percen}</span> %
                                </p>
                              </>
                            )}
                          </li>
                        ))}
                      </BalanceListToken>
                    </BalanceToken>
                  </BalanceBlock>
                </BlockFrame>
              </DashboardBalance>
              <BlockFrame
                style={{
                  maxWidth: "450px",
                  width: "100%",
                }}
              >
                <LevelWrapper>
                  <LevelHeader>
                    <figure>
                      <img
                        width={37}
                        height={35}
                        src={
                          miningData?.imgFrom ||
                          "./img/Dashboard/mining/mining_1.png"
                        }
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <div>
                      <p>{miningData?.pair || "Nebula V100-Node1"}</p>
                      <span>
                        {miningData?.textTo === "More than $30,000"
                          ? "More than $30,000"
                          : `${miningData?.textFrom || "$100"} - ${
                              miningData?.textTo || "$999"
                            }`}
                      </span>
                    </div>
                  </LevelHeader>
                  <LevelBody>
                    <figure>
                      <img
                        width={165}
                        height={156}
                        src={
                          miningData?.icon || "./img/Common/level/level_1.png"
                        }
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <LevelInfo>
                      <div>
                        <p>TLV</p>
                        <span>{miningData?.tvl || "$543,673.65"}</span>
                      </div>
                      <div>
                        <p>APR</p>
                        <span>{miningData?.apr || "48%"}</span>
                      </div>
                    </LevelInfo>
                    <div>
                      <MiningReward>
                        <div>
                          <p>Total Staked</p>
                          <img
                            width={14}
                            height={14}
                            src="./img/Common/hint.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </div>
                        <p>
                          ${userProfile?.wallet ? formatNuberMega(userProfile?.wallet?.totalStaking) : '--'}
                        </p>
                      </MiningReward>
                      <MiningReward>
                        <div>
                          <p>Reward Earning</p>
                          <img
                            width={14}
                            height={14}
                            src="./img/Common/hint.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </div>
                        <p>
                          $
                          {userProfile?.wallet
                            ? formatNuberMega(
                                userProfile?.wallet?.totalRewardStaking * usdtPrice
                              )
                            : "--"}
                        </p>
                      </MiningReward>
                    </div>
                  </LevelBody>
                </LevelWrapper>
              </BlockFrame>
              <BlockFrame
                className="block-ranked"
                style={{
                  maxWidth: "450px",
                  width: "100%",
                  background: `url("./img/Dashboard/level_bg.png") no-repeat center / cover`,
                }}
              >
                <LevelUpBlock>
                  <LevelUpTop>
                    <figure>
                      <img
                        width={46}
                        height={48}
                        src="./img/Dashboard/star.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <div>
                      <Tooltip placement="top" title={"Hint"}>
                        <img
                          width={19}
                          height={18}
                          src="./img/Common/hint.svg"
                          alt="icon"
                          loading="lazy"
                        />
                      </Tooltip>
                      <p>How to Level Up</p>
                    </div>
                  </LevelUpTop>
                  <LevelUpContent>
                    <Swiper
                      effect={"cards"}
                      grabCursor={true}
                      modules={[EffectCards]}
                      className="cards"
                    >
                      {swiperData.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <LevelCard>
                              <ButtonCommon className="green">
                                <img
                                  src="./img/Dashboard/bell.svg"
                                  alt="icon"
                                  loading="lazy"
                                />
                              </ButtonCommon>
                              <LevelContent>
                                <h2>{item.rank}</h2>
                                <span>{item.description}</span>
                                <div>
                                  <p>{item.value}</p>
                                  <ButtonCommon className="green-light">
                                    <img
                                      src="./img/Dashboard/card_icon.svg"
                                      alt="icon"
                                      loading="lazy"
                                    />
                                  </ButtonCommon>
                                </div>
                              </LevelContent>
                            </LevelCard>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </LevelUpContent>
                </LevelUpBlock>
              </BlockFrame>
            </DashboardBottom>
          </DashboardContent>
        </>
      ) : (
        <>
          <DashboardContent>
            {/* <DashboardNoti /> */}
            <DashboardBottom>
              <DashboardBalance>
                <BalanceHeader>
                  <TitleCommon
                    title="Total Balance"
                    description="The sum of all amount on my wallet"
                  />
                </BalanceHeader>
                <BlockFrame>
                  <BalanceBlock>
                    <BalanceBlockHeader>
                      <h2>
                        $
                        {userProfile?.wallet
                          ? formatNuberMega((
                            userProfile?.wallet?.usdt.balance +
                            userProfile?.wallet?.oni?.balance * poolInfoData?.base_token_price_usd
                          ))
                          : "0"}
                      </h2>
                    </BalanceBlockHeader>
                    <p>Total USDT & ONI Balance</p>
                    <BalanceToken>
                      <BalanceListToken>
                        {listBalance.map((item: any, index: number) => (
                          <li key={index}>
                            <p className="text-balance">{item.text}</p>
                            <div className="content-number">
                              <span className="text-number">{item.number}</span>
                              <img src={item.icon} alt={item.text} />
                            </div>
                            {item.priceUsd && <PriceUsd>${item.priceUsd}</PriceUsd>}
                            {item.lastText && (
                              <>
                                <p className="last-text">
                                  {item.lastText} <span>{item.percen}</span> %
                                </p>
                              </>
                            )}
                          </li>
                        ))}
                      </BalanceListToken>
                    </BalanceToken>
                  </BalanceBlock>
                </BlockFrame>
              </DashboardBalance>
              <DashboardTop>
                <BlockFrame>
                  <DashboardTopTitle>
                    <DashboardTopHeading>
                      <TitleCommon
                        title="Dashboard"
                        description="My top pool of potential candidates for the position"
                      />
                      {/* <div className="content-pack">
                          <img
                            src="/img/Dashboard/icn_pack_02.png"
                            width={44}
                            height={44}
                            alt="Pack"
                          />
                          <p>Hawking DataProc-01</p>
                        </div> */}
                    </DashboardTopHeading>
                  </DashboardTopTitle>
                  <DashboardTopInner>
                    <PotentialPoolList>
                      {potentialPoolSP1.map((item, index) => {
                        return (
                          <li key={index} className={`item-${item.id}`}>
                            <div>
                              <ProfileCommon
                                avatar={item.img}
                                name={item.name}
                                // subName={item.subName}
                              />
                            </div>
                            <div>
                              <span>{item.yourLP}</span>
                              <p>
                                {item.value}{" "}
                                <img src={item.icon} width={32} alt="" />
                              </p>
                              {item.priceUsd && <PriceUsd>${item.priceUsd}</PriceUsd>}
                            </div>
                          </li>
                        );
                      })}
                    </PotentialPoolList>
                    <PotentialPoolList className="list-2">
                      {potentialPoolSP2.map((item, index) => {
                        return (
                          <li key={index} className={`item-${item.id}`}>
                            <div>
                              <ProfileCommon
                                avatar={item.img}
                                name={item.name}
                                // subName={item.subName}
                              />
                            </div>
                            <div>
                              <span>{item.yourLP}</span>
                              <p>
                                {item.value}{" "}
                                <img src={item.icon} width={32} alt="" />
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </PotentialPoolList>
                  </DashboardTopInner>
                </BlockFrame>
              </DashboardTop>
              <DashboardMaxOutMain>
                <BlockFrame
                  style={{
                    background: `url("./img/Dashboard/bg_maxout.png") no-repeat center / 100% 100%`,
                  }}
                >
                  <DashboardMaxOut>
                    <MaxoutLeft>
                      <ProfileCommon
                        avatar="/img/Dashboard/icn_maxOut.png"
                        name="Max-Out"
                        subName="Dashboard"
                      />
                    </MaxoutLeft>
                    <MaxOutCircular>
                      <CircularProgressbar
                        styles={buildStyles({
                          pathColor: `#5EE978`,
                        })}
                        background={false}
                        value={
                          address && userProfile?.wallet
                            ? (userProfile?.wallet?.maxout /
                                (userProfile?.wallet?.totalStaking * 3)) *
                              100
                            : 0
                        }
                      />
                      <MaxOutInfo>
                        <div>
                          <p>
                          {(address && userProfile?.wallet?.maxout > 0)
                            ? Number(
                                (userProfile?.wallet?.maxout /
                                  (userProfile?.wallet?.totalStaking * 3)) *
                                100
                              )?.toFixed(2)
                            : '0'}
                            %
                          </p>
                          <p>Percentage</p>
                        </div>
                        {/* <span>Updated: 15/08/2024</span> */}
                      </MaxOutInfo>
                      <p className="text-max-out">
                        Total Profit
                        <br />{" "}
                        <span>
                          ${address && userProfile?.wallet
                            ? formatNuberMega(userProfile?.wallet?.maxout)
                            : 0}
                          /
                          ${address && userProfile?.wallet
                            ? formatNuberMega(
                                userProfile?.wallet?.totalStaking * 3
                              )
                            : 0}
                        </span>
                      </p>
                    </MaxOutCircular>
                  </DashboardMaxOut>
                </BlockFrame>
              </DashboardMaxOutMain>
              <BlockFrame
                style={{
                  width: "calc(100% - 24px)",
                }}
              >
                <LevelWrapper>
                  <LevelHeader>
                    <figure>
                      <img
                        width={37}
                        height={35}
                        src={
                          miningData?.imgFrom ||
                          "./img/Dashboard/mining/mining_1.png"
                        }
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <div>
                      <p>{miningData?.pair || "Nebula V100-Node1"}</p>
                      <span>
                        {miningData?.textTo === "More than $30,000"
                          ? "More than $30,000"
                          : `${miningData?.textFrom || "$100"} - ${
                              miningData?.textTo || "$999"
                            }`}
                      </span>
                    </div>
                  </LevelHeader>
                  <LevelBody>
                    <figure>
                      <img
                        width={165}
                        height={156}
                        src={
                          miningData?.icon || "./img/Common/level/level_1.png"
                        }
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <LevelInfo>
                      <div>
                        <p>TLV</p>
                        <span>{miningData?.tvl || "$543,673.65"}</span>
                      </div>
                      <div>
                        <p>APR</p>
                        <span>{miningData?.apr || "48%"}</span>
                      </div>
                    </LevelInfo>
                    <div>
                      <MiningReward>
                        <div>
                          <p>Total Staked</p>
                          <img
                            width={14}
                            height={14}
                            src="./img/Common/hint.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </div>
                        <p>
                          ${userProfile?.wallet ? formatNuberMega(userProfile?.wallet?.totalStaking) : '--'}
                        </p>
                      </MiningReward>
                      <MiningReward>
                        <div>
                          <p>Reward Earning</p>
                          <img
                            width={14}
                            height={14}
                            src="./img/Common/hint.svg"
                            alt="icon"
                            loading="lazy"
                          />
                        </div>
                        <p>
                          $
                          {userProfile?.wallet
                            ? formatNuberMega(
                                userProfile?.wallet?.totalRewardStaking * usdtPrice
                              )
                            : "--"}
                        </p>
                      </MiningReward>
                    </div>
                  </LevelBody>
                </LevelWrapper>
              </BlockFrame>
              <BlockFrame
                style={{
                  marginTop: !isDesktop ? "25px" : "20px",
                  maxWidth: "100%",
                  background: `url("./img/Dashboard/level_bg.png") no-repeat center / cover`,
                }}
              >
                <LevelUpBlock>
                  <LevelUpTop>
                    <figure>
                      <img
                        width={46}
                        height={48}
                        src="./img/Dashboard/star.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </figure>
                    <div>
                      <Tooltip placement="top" title={"Hint"}>
                        <img
                          width={19}
                          height={18}
                          src="./img/Common/hint.svg"
                          alt="icon"
                          loading="lazy"
                        />
                      </Tooltip>
                      <p>How to Level Up</p>
                    </div>
                  </LevelUpTop>
                  <LevelUpContent>
                    <Swiper
                      effect={"cards"}
                      grabCursor={true}
                      modules={[EffectCards]}
                      className="cards"
                    >
                      {swiperData.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <LevelCard>
                              <ButtonCommon className="green">
                                <img
                                  src="./img/Dashboard/bell.svg"
                                  alt="icon"
                                  loading="lazy"
                                />
                              </ButtonCommon>
                              <LevelContent>
                                <h2>{item.rank}</h2>
                                <span>{item.description}</span>
                                <div>
                                  <p>{item.value}</p>
                                  <ButtonCommon className="green-light">
                                    <img
                                      src="./img/Dashboard/card_icon.svg"
                                      alt="icon"
                                      loading="lazy"
                                    />
                                  </ButtonCommon>
                                </div>
                              </LevelContent>
                            </LevelCard>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </LevelUpContent>
                </LevelUpBlock>
              </BlockFrame>
            </DashboardBottom>
          </DashboardContent>
        </>
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
